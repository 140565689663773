@import '../../assets/scss/variables';

.contactMe{
    background-color: #111;
    padding: 5rem 0;
    min-height: 20vh;
    section{
        @include maxWidth;
        color: $light-text-color;
        display:flex;
        gap: 5rem;
        align-items: center;
        & > *{
            flex: 1;

        }
        & >:first-child{
            border-right: 1px solid $light-text-color;
        }
        a{
            color: $light-text-color;
            justify-content: center;
            align-items: center;
            display: inline-flex;
            gap: 1rem;
            text-decoration: none;
        }
        @media (max-width:720px) {
            flex-direction: column;
            & >:first-child{
                border-right: none;
            }
            
        }
        
    }

}