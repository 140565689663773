@import "../../assets/scss/variables";

.landingPage {
  background-color: $header-bg;
  color: $light-text-color;
  height: calc(100vh - 15rem);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media  (max-width: 720px) {
    height: 80vh;
  }
  .brandContent {
    @include maxWidth;
    flex: 1;
    font-size: 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media  (max-width: 720px) {
      padding: 1rem 4rem;
    }
    & > * {
      line-height: 1.5;
    }

    .superHeading {
      font-size: 3rem;
    }
    .heading {
      font-size: 7rem;
      color: $highlight-color;
      font-weight: 500;
      @media  (max-width: 720px) {
        line-height: 1;
       }
    }
  }
  .landingFooter {
    .waves {
      position: absolute;
      width: 100%;
      height: 7vh;
      min-height: 70px;
      max-height: 100px;
      bottom: 0;
      left: 0;
    }

    .parallax > use {
      animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
    }

    .parallax > use:nth-child(1) {
      animation-delay: -2s;
      animation-duration: 7s;
    }

    .parallax > use:nth-child(2) {
      animation-delay: -3s;
      animation-duration: 10s;
    }

    .parallax > use:nth-child(3) {
      animation-delay: -4s;
      animation-duration: 13s;
    }

    .parallax > use:nth-child(4) {
      animation-delay: -5s;
      animation-duration: 20s;
    }

    @keyframes move-forever {
      0% {
        transform: translate3d(-90px, 0, 0);
      }

      100% {
        transform: translate3d(85px, 0, 0);
      }
    }

    @media (max-width: 768px) {
      .waves {
        height: 40px;
        min-height: 40px;
      }
    }
  }
}
