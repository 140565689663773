@import-normalize;

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import "./assets/scss/variables";

:root {
  --animate-duration: 800ms;
  --animate-delay: 0.9s;
  --animate-repeat: 5
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: "Raleway", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  color: $dark-text-color;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  line-height: 2;
}

button,
input[type="submit"],
a.button {
  border: 2px solid $highlight-color;
  border-radius: 1rem;
  background-color: $header-bg;
  padding: 0 2rem;
  color: $light-text-color;
  cursor: pointer;
  font-size: 2rem;
  &:hover {
    background-color: $highlight-color;
    text-decoration: underline;
    color: $dark-text-color;
  }
}
