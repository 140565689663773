$header-bg: #000345;
$highlight-color: #ffc409;
$light-text-color: #f5f5f5;
$dark-text-color: #1b1919;

@mixin maxWidth {
  max-width: 1080px;
  margin: 0 auto;
}

@mixin loadFlex {
  display: flex;
  justify-content: center;
}

@mixin loadFlexStart{
    @include loadFlex;
    align-items: flex-start;
}
