@import "../../assets/scss/variables";

.aboutMe {
  @include maxWidth;
  @include loadFlex;
  align-items: center;
  height: 50vh;
  align-items: stretch;
  padding: 7rem 0;
  p::first-letter {
    font-size: 2rem;
  }

  & > * {
    flex: 1;
    display: flex;
    align-items: center;
  }
  h2{
    justify-items: center;
  }
  & :last-child {
    padding: 0 5rem;
    border-left: 2px solid $highlight-color;
  }
  @media (max-width: 720px) {
    min-height: 100vh;
    flex-direction: column;
    padding: 7rem 2rem;
    gap: 3rem;
    & > * {
      flex:none;
    }
    & :last-child {
      padding: 0 3rem 0 0;
      border-left: none;
    }
  }
}
