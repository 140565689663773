@import "./assets/scss/variables";

main {
  h2 {
    font-size: 3rem;
    font-weight: 600;
    color: $header-bg;
  }
}

footer {
  background-color: #111;
 
  section {
    @include maxWidth;
    padding: 2rem 0;
    border-top: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    color: lighten($color: $dark-text-color, $amount: 50);
    strong {
      color: $light-text-color;
      font-weight: 600;
    }
    @media (max-width:720px) {
      justify-content: space-around;
    }
  }
}
