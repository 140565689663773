@import "../../assets/scss/variables";

.skillToolsContainer {
  @include loadFlex;
  justify-content: flex-start;
  min-height: 100vh;
  flex-direction: column;
  @include maxWidth;
  padding: 2rem 0;
  gap: 5rem;
  .listContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4rem;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 1rem;
      p{
        font-weight: 500;
      }
      img{
        width: 80px;
      }
    }
  }
  @media (max-width:720px) {
    padding: 2rem;
    .listContainer{
      gap: 3rem;
      margin: 2rem 0;
      div{
        width: 100px;
        img{
          width: 40px;
        }
      }
    }
  }
}
