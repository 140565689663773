@import "../../assets/scss/variables";

.experienceContainer{
    background-color: $header-bg;
    section{
        @include maxWidth;
        @include loadFlex;
        gap: 5rem;
        flex-direction: column;
        justify-content: flex-start;
        padding: 6rem 0 8rem;
        color: $light-text-color;
        h2{
            text-align: center;
            color: $highlight-color;
        }
        p::first-letter{
            font-size: 2rem;
            font-weight: 500;
        }
    }
    @media (max-width: 720px){
      padding: 0 2rem;
    }
}

.experience {
  @include maxWidth;
  @include loadFlex;
  min-height: 70vh;
  align-items: stretch;
  padding: 7rem 0;
  & > * {
    flex: 1;
    display: flex;
    align-items: center;
  }
  p{
    padding: 0 5rem;
  }
  & :last-child{
    justify-content: center;
    border-left: 2px solid $highlight-color;
  }
  @media (max-width: 720px) {
    flex-direction: column;
    flex-flow: column-reverse;
    p{
      padding: 0 2rem;
      align-items: flex-start;
    }
    h2{
      padding: 0 2rem;
      justify-content: flex-start;
    }
    & :last-child{
      justify-content: flex-start;
      border-left: none;
    }
    
  }
}
