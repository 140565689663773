@import "../../assets/scss/variables";

.mainHeader {
  background-color: $header-bg;
  color: $light-text-color;
  & > section {
    @include maxWidth;
    display: flex;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: center;
    a {
      text-decoration: none;
      color: $light-text-color;
      display: block; 
      &.button{
        &:hover{
            color:$light-text-color;
        }
      }
      &:hover {
        color: $highlight-color;
        text-decoration: none;
      }
    }
    h1 {
      font-size: 3.4rem;
      font-weight: 400;
    }
    img{
      width: 5rem;
    }
    .mainBrand {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0 1rem;
    }
    .mainNav {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      a {
        text-align: center;
        flex: 1;
      }
    }
  }
  @media (max-width: 720px) {
    section{
      max-width: 100%;
      flex-wrap: wrap;
      h1 {
        font-size: 2.4rem;
      }
      img{
        width: 4rem;
      }
      .mainBrand {
        flex-direction: row;
      }
      .contactBtn{
        display: none;
      }
    }
  }
}
